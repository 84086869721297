/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_firebase.js
 *
 *  Description: Provides all the firebase functioality
 *
 *  Author: Nabin Kharal (nabin@brainants.com), Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import { getTimeInEpoch } from '../helpers/date';

// stores the configuation data for the firebase project
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

let companyKey = null;

let init = false;

/*
 *************************************************************************************
 * @brief  (initFirebase) initializes firebase application, should be initialized only
 * once for a firebase project
 * @param{} ()
 *
 * @returns undefined
 *************************************************************************************
 */
export function initFirebase(setUser) {
  if (!init) {
    firebase.initializeApp(config);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDetails = await getUserInfo(user?.uid);
          if (userDetails?.loggedIn) {
            const tokenId = await user.getIdToken();
            const claims = parseJwt(tokenId);
            if (claims.customer) {
              companyKey = claims.company;
              setUser(claims);
            } else {
              setUser({});
            }
          } else {
            setUser({});
          }
        } catch (error) {
          console.error('@@error', error);
        }
      } else {
        setUser({});
      }
    });
    init = true;
  }
}

export const { auth } = firebase;

// authentication functions
/*
 ****************************************************************************
 * @brief  (loginWithEmailPassword) tries to login with given email and password
 * @param{type {string,string}} (email,password)
 *
 * @returns promise
 ****************************************************************************
 */
export async function loginWithEmailPassword(email, password, history) {
  const loggedinInfo = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);

  const userDetails = await getUserInfo(loggedinInfo.user?.uid);
  const token = await loggedinInfo.user.getIdToken();
  const claims = parseJwt(token);

  if (!userDetails?.loggedIn && claims?.customer) {
    history.push({
      pathname: '/changePassword',
      state: {
        email,
        password,
      },
    });
  }

  if (claims.customer) {
    return {
      status: 'true',
      message: 'Successfully logged In',
    };
  }

  const error = {
    status: false,
    message: 'You are not authorized to login',
  };
  throw error;
}

export async function updatePassword(newPassword, oldPassword, fromProfile) {
  const user = firebase.auth().currentUser;

  const { email, uid } = user;

  const credential = firebase.auth.EmailAuthProvider.credential(
    email,
    oldPassword
  );

  await user.reauthenticateWithCredential(credential);
  await firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .update({ loggedIn: true });
  await user.updatePassword(newPassword);
  if (!fromProfile) {
    window.location.replace('/home');
  }
}

export async function getUserInfo(uid, updateUser) {
  const ref = firebase.firestore().collection('users').doc(uid);
  if (updateUser) {
    return ref.onSnapshot((doc) => {
      updateUser(doc.data() ?? {});
    });
  }
  const response = await ref.get();
  return response.data() ?? {};
}

/*
 ****************************************************************************
 * @brief  (createNewProvider) creates new provider using the provided credentials
 * @param{type {Object}} (provider)
 *
 * @returns promise
 ****************************************************************************
 */
export async function createCrew(prov) {
  const provider = { ...prov };
  provider.companyKey = companyKey;
  if (provider.uid) {
    return firebase
      .firestore()
      .collection('providers')
      .doc(provider.uid)
      .set({ ...provider }, { merge: true });
  }
  const addProviderFunc = firebase
    .functions()
    .httpsCallable('providersGroup-providersGroup-createServiceProvider');

  return addProviderFunc({ ...provider });
}

export function getCode(email) {
  const getCodeForEmail = firebase
    .functions()
    .httpsCallable('authGroup-authGroup-sendResetPassword');

  return getCodeForEmail({ email, app: 'customer' });
}

export async function resetPassword({ code, password }) {
  const res = await firebase
    .database()
    .ref()
    .child('res-data')
    .child(code)
    .once('value');

  const oobCode = res.val();

  if (!oobCode) {
    throw new Error();
  }

  return firebase.auth().confirmPasswordReset(oobCode, password);
}

export async function getRealTimeProviders(updateProviders) {
  firebase
    .firestore()
    .collection('providers')
    .where('companyKey', '==', companyKey)
    .onSnapshot((data) => {
      const _allProviders = {};
      data.docs.forEach((p) => {
        _allProviders[p.data().uid] = p.data();
      });
      updateProviders(_allProviders);
    });
}

export async function getLocationOfProvider(providerUid, onChange) {
  firebase
    .database()
    .ref()
    .child('locations')
    .child(companyKey)
    .child(providerUid)
    .on('value', (snap) => {
      onChange(providerUid, snap.val());
    });
}

// returns location information of all providers inside a company
export async function getProvidersLocation() {
  const locs = await firebase
    .database()
    .ref()
    .child('locations')
    .child(companyKey)
    .once('value');
  return locs.val() ?? {};
}

function getCrewsLocations(data, uniqueUids) {
  return Object.entries(data ?? {}).reduce((acc, locationData) => {
    if (locationData[1]?.latitude && uniqueUids.has(locationData[0])) {
      return [...acc, { uid: locationData[0], ...(locationData[1] ?? {}) }];
    }
    return acc;
  }, []);
}

export async function getCrewsLocationsOfAJob(jobKey) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .get();

  const job = response?.data() ?? {};

  if (!job?.companyKey) return [];

  const crewUids = new Set(job?.providerUids ?? []);

  if (!crewUids) return [];

  const data = await firebase
    .database()
    .ref()
    .child('locations')
    .child(job?.companyKey)
    .once('value');

  const crewLocations = getCrewsLocations(data.val(), crewUids);

  return crewLocations ?? [];
}

// gets and updates location information of all the providers of the customers
export async function getRealTimeLocationOfAllProviders(markers, onChange) {
  if (!markers || markers?.length === 0) return onChange([]);

  companyKey = markers[0]?.companyKey;

  const promises = markers?.map((marker) => {
    return firebase
      .firestore()
      .collection('marker_logs')
      .where('servicePointId', '==', marker.key)
      .get();
  });

  const markerLogsResponse = await Promise.allSettled(promises ?? []);

  const crewUids = markerLogsResponse?.reduce((acc, response) => {
    if (response?.status === 'fulfilled') {
      const markerLog = response.value.docs[0]?.data() ?? {};

      if (markerLog?.working && markerLog?.providerUid) {
        return [...acc, markerLog?.providerUid];
      }
      return acc;
    }
    return acc;
  }, []);

  const uniqueUids = new Set(crewUids);

  return firebase
    .database()
    .ref()
    .child('locations')
    .child(companyKey)
    .on('value', (snap) => {
      const data = getCrewsLocations(snap.val(), uniqueUids);
      onChange(data ?? []);
    });
}

function chunk(array, chunkSize) {
  const length = array?.length ?? 0;
  const chunks = [];
  for (let i = 0; i < length; i += chunkSize) {
    const size = i + chunkSize;
    const newArray = array.slice(i, size > length ? length : size);
    chunks.push(newArray);
  }

  return chunks;
}

function getPromiseValue(response) {
  const values = response?.reduce((acc, eachResponse) => {
    if (eachResponse.status === 'fulfilled') {
      const value = eachResponse.value.docs.map((d) => d.data());
      return [...acc, ...value];
    }
    return acc;
  }, []);

  return values ?? [];
}

export async function getRealTimeLogs(markers) {
  if (!markers || markers?.length === 0) return [];

  const markerKeys = markers?.map((marker) => marker.key);

  const key = markers[0].companyKey;

  const markersChunks = chunk(markerKeys, 10);

  const promise = markersChunks?.map((keysMarkers) => {
    return firebase
      .firestore()
      .collection('customers')
      .doc(key)
      .collection('logs')
      .where('markerKey', 'in', keysMarkers)
      .orderBy('date', 'desc')
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const allLogs = getPromiseValue(response);

  return allLogs;
}

export function getRealTimeRoutes(updateRoutes) {
  let _allRoutes = {};
  return firebase
    .firestore()
    .collection('routes')
    .where('companyKey', '==', companyKey)
    .onSnapshot((data) => {
      _allRoutes = {};
      data.docs.forEach((r) => {
        _allRoutes[r.data().key] = r.data();
        // _allRoutes = { ...r.data() }
      });
      updateRoutes(_allRoutes);
    });
}

export async function editRoute(r) {
  const route = { ...r };
  return firebase
    .firestore()
    .collection('routes')
    .doc(route.key)
    .update({ ...route });
}

export async function createServiceRoute(r) {
  const route = { ...r };
  route.companyKey = companyKey;
  const docRef = firebase.firestore().collection('routes').doc();
  route.key = docRef.id;
  await docRef.set(route);
  return docRef.key;
}

export function getRealTimeServicePoints(updateServicePoints) {
  const user = firebase.auth().currentUser;
  return firebase
    .firestore()
    .collection('servicePoints')
    .where('customerDetail.uid', '==', user.uid)
    .onSnapshot((data) => {
      const markersOfACustomer = data.docs.map((d) => d.data(0));
      updateServicePoints(markersOfACustomer);
    });
}

export function getRealTimeUnAssignedServicePoints(updateServicePoints) {
  let _allServicePoints = {};
  return firebase
    .firestore()
    .collection('servicePoints')
    .where('companyKey', '==', companyKey)
    .where('assigned', '==', false)
    .onSnapshot((data) => {
      _allServicePoints = {};
      data.docs.forEach((p) => {
        _allServicePoints[p.data().key] = p.data();
      });
      updateServicePoints(_allServicePoints);
    });
}

/*
 ****************************************************************************
 * @brief  get the service point by id
 * @param{type {string}} (servicePointId)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getServicePointById(servicePointId) {
  const servicePoint = await firebase
    .firestore()
    .collection('servicePoints')
    .doc(servicePointId)
    .get();

  return servicePoint.data() ?? {};
}

/*
 ****************************************************************************
 * @brief  get the provider  by uid
 * @param{type {string}} (providerUid)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getProviderByUid(providerUid) {
  const result = await firebase
    .firestore()
    .collection('providers')
    .doc(providerUid)
    .get();

  return result.data() ?? {};
}

/*
 ****************************************************************************
 * @brief  get the route by key.
 * @param{type {string}} (routeKey)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getRouteByKey(routeKey) {
  const routeData = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .get();

  return routeData.data();
}

export function getRealTimeServices(updateServices) {
  let _allServices = {};
  return firebase
    .firestore()
    .collection('services')
    .where('companyKey', '==', companyKey)
    .onSnapshot((data) => {
      _allServices = {};
      data.docs.forEach((p) => {
        _allServices[p.data().key] = p.data();
      });
      updateServices(_allServices);
    });
}

/*
 ****************************************************************************
 * @brief  get the services of a company
 * @param{type {}} ()
 *
 * @returns promise
 ****************************************************************************
 */
export async function getServices() {
  const servicesData = await firebase
    .firestore()
    .collection('services')
    .where('companyKey', '==', companyKey)
    .get();

  return servicesData.docs;
}
/*
 ****************************************************************************
 * @brief  (logout) logs the current user out from firebase
 * @param{type {}} ()
 *
 * @returns promise
 ****************************************************************************
 */
export async function logout() {
  await firebase.auth().signOut();
  companyKey = null;
  window.location.reload();
}

/*
 ****************************************************************************
 * @brief  (parseJwt) parse the jwt token
 * @param{type {string}} (token)
 *
 * @returns object
 ****************************************************************************
 */
export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

/*
 ****************************************************************************
 * @brief  (getCurrentUser) calls the given callback whenever authentication
 *  state is changed
 * @param{type {function}} (onChanged)
 *
 * @returns undefuned
 ****************************************************************************
 */
export async function getCurrentUser(onChange) {
  firebase.auth().onAuthStateChanged(async (user) => {
    onChange(user);
  });
}

/*
 ****************************************************************************
 * @brief  (createNewService) creates new service using the provided credentials
 * @param{type {Object}} (service)
 *
 * @returns promise
 ****************************************************************************
 */
export async function createEditService(serv) {
  const service = { ...serv };
  service.companyKey = companyKey;
  let ref;
  if (!service.key) {
    ref = firebase.firestore().collection('services').doc();
    service.key = ref.id;
  } else {
    ref = firebase.firestore().collection('services').doc(service.key);
  }
  return ref.set(service);
}

// BeaconM firebase functions

/*
 ****************************************************************************
 * @brief  (getRealTimeBeaconMs) calls the given callback BeaconMs are changed
 *
 * @param{type {function}} (updateBeaconMs)
 *
 * @returns undefined
 ****************************************************************************
 */
export async function getRealTimeBeaconMs(updateBeaconMs) {
  const _allBeaconMs = {};
  firebase
    .firestore()
    .collection('beacons')
    .where('companyKey', '==', companyKey)
    .onSnapshot((data) => {
      data.docs.forEach((p) => {
        _allBeaconMs[p.data().uuid] = p.data();
      });
      updateBeaconMs(_allBeaconMs);
    });
}

/*
 ****************************************************************************
 * @brief  (getUnAssignedBeacons) returns the list of unassigned beacons
 *
 * @param{type {}} ()
 *
 * @returns promise
 ****************************************************************************
 */

export function getRealTimeUnAssignedBeacons(updateBeacons) {
  return firebase
    .firestore()
    .collection('beacons')
    .where('companyKey', '==', companyKey)
    .where('assigned', '==', false)
    .onSnapshot((data) => {
      updateBeacons(data.docs.map((p) => p.data()));
    });
}

/*
 ****************************************************************************
 * @brief  (uploadImageToStorage) upload service point image
 *
 * @param{type {File, string}} (image, imageName)
 *
 * @returns promise
 ****************************************************************************
 */
export async function uploadImageToStorage(image, imageName) {
  const snapshot = await firebase
    .storage()
    .ref()
    .child(`service_point_images/${imageName}`)
    .put(image);
  return snapshot.ref.getDownloadURL();
}

/*
 ****************************************************************************
 * @brief  (addNewServicePoint) add new service point
 *
 * @param{type {Object} (formData)
 *
 * @returns promise
 ****************************************************************************
 */
export async function addEditNewServicePoint(formData) {
  const data = { ...formData };
  data.companyKey = companyKey;
  let ref;
  if (!data.key) {
    const getName = await firebase
      .firestore()
      .collection('servicePoints')
      .where('name', '==', data.name)
      .get();

    if (getName.docs.length > 1) {
      return true;
    }

    ref = firebase.firestore().collection('servicePoints').doc();
    data.key = ref.id;
  } else {
    ref = firebase.firestore().collection('servicePoints').doc(data.key);
  }
  return ref.set(data);
}

/*
 ****************************************************************************
 * @brief  (getRealTimeJobs) calls the given callback Jobs are changed
 *
 * @param{type {function}} (updateJobs)
 *
 * @returns undefined
 ****************************************************************************
 */

export function getRealTimeJobs(markers, updateJobs) {
  const markersKeys = markers?.map((marker) => marker?.key) ?? [];

  if (markersKeys?.length === 0) return updateJobs([]);

  const ref = firebase
    .firestore()
    .collection('jobs')
    .where('allMarkers', 'array-contains-any', markersKeys)
    .orderBy('createdDate', 'desc');

  return ref.onSnapshot((data) => {
    const response = data?.docs?.map((d) => d.data()) ?? [];
    updateJobs(response);
  });
}

export async function getRealTimePeriodicJobs(updateJobs) {
  const ref = firebase
    .firestore()
    .collection('periodicJobs')
    .where('companyKey', '==', companyKey)
    .orderBy('createdDate', 'desc');

  ref.onSnapshot((data) => {
    const _allJobs = {};
    data.docs.forEach((p) => {
      _allJobs[p.data().key] = p.data();
    });
    updateJobs(_allJobs);
  });
}

export async function getJobInfo(jobId) {
  let response = await firebase.firestore().collection('jobs').doc(jobId).get();

  if (response.data()) {
    return response.data();
  }

  response = await firebase
    .firestore()
    .collection('periodicJobs')
    .doc(jobId)
    .get();
  return response.data();
}

// firebase functions for pdf data

/*
 ****************************************************************************
 * @brief  (getActiveRoutesForProvider) returns the list of active routes
 * for a provider
 *
 * @param{type {string, string, string}} (providerUid, startDate, endDate)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getJobsForCrew(crewUid, startDate, endDate) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .where('companyKey', '==', companyKey)
    .where('providerUids', 'array-contains', crewUid)
    .orderBy('startedDate', 'desc')
    .where('startedDate', '>=', startDate)
    .get();

  const jobs = response.docs.reduce((accumulator, eachResponse) => {
    const job = eachResponse.data();
    const jobEndDate = job.endDate || 0;

    if (jobEndDate <= endDate) {
      return [...accumulator, job];
    }
    return accumulator;
  }, []);

  return jobs;
}

/*
 ****************************************************************************
 * @brief  (getProvidersOfCompany) returns the list of providers of company
 *
 * @param{type {}} ()
 *
 * @returns promise
 ****************************************************************************
 */

export async function getProvidersOfCompany() {
  const providers = await firebase
    .firestore()
    .collection('providers')
    .where('companyKey', '==', companyKey)
    .get();
  return providers.docs.map((provider) => provider.data()) || [];
}

export async function getRoutesForService(service) {
  const routes = await firebase
    .firestore()
    .collection('routes')
    .where('companyKey', '==', companyKey)
    .where('serviceKey', '==', service.key)
    .get();
  return routes.docs.map((route) => route.data()) || [];
}

export const getRoutesFromDatabase = async (routeKey = '') => {
  const result = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .get();

  return result.data()?.name;
};

export const getCrewsFromDatabase = async (crewKey) => {
  const result = await firebase
    .firestore()
    .collection('providers')
    .doc(crewKey)
    .get();
  return result.data()?.name || '';
};
/*
 ****************************************************************************
 * @brief  (getRouteInfo) returns route according to routeKey
 *
 * @param{type {string}} (routeKey)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getRouteInfo(routeKey) {
  const response = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .get();

  return response.data() || {};
}

/*
 ****************************************************************************
 * @brief  (getServiceInfo) returns service according to serviceId
 *
 * @param{type {string}} (serviceId)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getServiceInfo(serviceId) {
  const response = await firebase
    .firestore()
    .collection('services')
    .doc(serviceId)
    .get();
  return response.data() || {};
}

/*
 ****************************************************************************
 * @brief  (getJobsForActiveRoute) returns jobs for a active route
 *
 * @param{type {string}} (activeRouteKey)
 *
 * @returns promise
 ****************************************************************************
 */

export async function getJobsForRoute(routeKey, startDate, endDate) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .where('companyKey', '==', companyKey)
    .where('allRoutes', 'array-contains', routeKey)
    .orderBy('startedDate', 'desc')
    .where('startedDate', '>=', startDate)
    .get();

  const jobs = response.docs.reduce((accumulator, eachResponse) => {
    const job = eachResponse.data();
    const jobEndDate = job.endDate || 0;

    if (jobEndDate <= endDate) {
      return [...accumulator, job];
    }
    return accumulator;
  }, []);

  return jobs;
}

// service point by id is up ^^^^^

/*
 ****************************************************************************
 * @brief  (deleteServicePoint) deletes the service point
 *
 * @param{type {string}} (servicePointKey)
 ****************************************************************************
 */

export async function deleteServicePoint(servicePointKey) {
  return firebase
    .firestore()
    .collection('servicePoints')
    .doc(servicePointKey)
    .delete();
}

export async function deleteJob(jobKey) {
  return firebase.firestore().collection('jobs').doc(jobKey).delete();
}

export const deletePeriodicJob = (jobKey) => {
  return firebase.firestore().collection('periodicJobs').doc(jobKey).delete();
};

export function deleteServiceRoute(routeKey) {
  return firebase.firestore().collection('routes').doc(routeKey).delete();
}

/*
 ****************************************************************************
 * @brief  (deleteProvider) deletes the provider.
 *
 * @param{type {string}} (providerUid)
 ****************************************************************************
 */

export async function deleteProvider(providerUid) {
  return firebase.firestore().collection('providers').doc(providerUid).delete();
}

/*
 ****************************************************************************
 * @brief  (deleteService) deletes the service
 *
 * @param{type {string}} (serviceKey)
 ****************************************************************************
 */

export async function deleteService(serviceKey) {
  return firebase.firestore().collection('services').doc(serviceKey).delete();
}

export async function getAllServiceRoutes() {
  const routes = await firebase
    .firestore()
    .collection('routes')
    .where('companyKey', '==', companyKey)
    .get();
  return routes.docs.map((route) => route.data());
}

/*
 ****************************************************************************
 * @brief  (getActiveRoutesForRoute) returns the list of active routes
 * for a route
 *
 * @param{type {string, string, string}} (providerUid, startDate, endDate)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getActiveRoutesForJob(jobKey) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('routes')
    .get();

  return response.docs.map((d) => d.data()) || [];
}

/*
 ****************************************************************************
 * @brief  (getCompanyDetail) returns detail of the company
 *
 * @param{type {}} ()
 *
 * @returns promise
 ****************************************************************************
 */

export async function getCompanyDetail() {
  const company = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey)
    .get();
  return company.data();
}

export const getCompanyKey = () => {
  return companyKey;
};

export async function assignProviderToRoute(routeKey, providerUid) {
  await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .update({ providerUid });
}

export async function getIssueReports(jobKey, _updateReports) {
  const ref = firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('reports')
    .orderBy('date', 'desc');
  if (_updateReports) {
    return ref.onSnapshot((data) => {
      const response = data.docs.map((d) => d.data()) || [];
      _updateReports(response);
    });
  }
  const response = await ref.get();
  return response.docs.map((d) => d.data()) ?? [];
}

function getArrayOfData(res) {
  return res.docs.map((d) => d.data()) ?? [];
}

export async function getIssueReportsOfAMarker(
  jobKey,
  markerKey,
  _updateReports
) {
  const ref = firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('reports')
    .where('markerKey', '==', markerKey);
  if (_updateReports) {
    return ref.onSnapshot((res) => {
      const response = res?.docs?.map((d) => d.data()) ?? [];
      _updateReports(response);
    });
  }
  const response = await ref.get();
  const reports = getArrayOfData(response);
  return reports;
}

export async function assignBeacon(uuid, locId) {
  if (uuid === null || locId === null) {
    throw Error('error');
  }
  try {
    firebase.firestore().collection('beacons').doc(uuid).update({
      locationId: locId,
      assigned: true,
    });
    return true;
  } catch (e) {
    throw Error(e);
  }
}

export async function unassignBeacon(uuid) {
  if (uuid === null) {
    throw Error('select beaon');
  }
  try {
    firebase.firestore().collection('beacons').doc(uuid).update({
      locationId: null,
      assigned: false,
    });
  } catch (e) {
    console.error(e);
    throw Error('error ');
  }
}

export async function getUnassignedIndoorServicePoints() {
  const servicePointsData = await firebase
    .firestore()
    .collection('servicePoints')
    .where('companyKey', '==', companyKey)
    .where('serviceType', '==', 'indoor')
    .where('assigned', '==', false)
    .get();

  return servicePointsData.docs.map((servicePoint) => servicePoint.data());
}

export async function getBeaconByBeaconId(beaconId) {
  const beaconData = await firebase
    .firestore()
    .collection('beacons')
    .doc(beaconId)
    .get();
  return beaconData.data();
}

export async function addBeaconIdToFirestore(beaconUuid, navigineBeaconId) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .where('beaconId', '==', beaconUuid)
    .get();

  const servicePointKey = response.docs[0].data().key;

  await firebase
    .firestore()
    .collection('servicePoints')
    .doc(servicePointKey)
    .update({
      navigineBeaconId: `${navigineBeaconId}`,
    });
}

export async function getLocationIdOfTheCompany() {
  return (
    await firebase.firestore().collection('customers').doc(companyKey).get()
  ).data().locationId;
}

export async function updateRouteData(routeKey, routeData) {
  const routeRef = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey);
  await routeRef.update(routeData);
  return routeRef;
}

export async function createEditJob(jobData) {
  const job = { ...jobData };
  const timeInEpoch = getTimeInEpoch();
  job.companyKey = companyKey;
  if (!job.key) job.createdDate = timeInEpoch;
  let ref;

  if (!job.key) {
    ref = firebase.firestore().collection('jobs').doc();
    job.key = ref.id;
  } else {
    ref = firebase.firestore().collection('jobs').doc(job.key);
  }
  return ref.set(job);
}

export async function createEditPeriodicJob(jobData) {
  const job = { ...jobData };

  job.companyKey = companyKey;
  if (!job.key) {
    const timeInEpoch = getTimeInEpoch();
    job.createdDate = timeInEpoch;
  }
  let ref;
  if (!job.key) {
    ref = firebase.firestore().collection('periodicJobs').doc();
    job.key = ref.id;
  } else {
    ref = firebase.firestore().collection('periodicJobs').doc(job.key);
  }
  return ref.set(job);
}
// service point by id is up ^^^^^ //

export async function providerLatestJob(providerId) {
  const ref = firebase.firestore().collection('jobs');
  return (
    await ref
      .where('providerUids', 'array-contains', providerId)
      .orderBy('createdDate', 'desc')
      .get()
  ).docs[0].data();
}

export const searchCrew = async (input) => {
  const lowerCaseInput = input.toLowerCase();

  const result = await firebase
    .firestore()
    .collection('providers')
    .where('companyKey', '==', companyKey)
    .where('label', '>=', lowerCaseInput)
    .where('label', '<=', `${lowerCaseInput}~`)
    .get();

  return result.docs.map((queryEachResult) => queryEachResult.data()) || [];
};

export const searchService = async (input, serviceType) => {
  const lowerCaseInput = input.toLowerCase();
  let result = {};

  if (serviceType === 'ALL') {
    result = await firebase
      .firestore()
      .collection('services')
      .where('companyKey', '==', companyKey)
      .where('label', '>=', lowerCaseInput)
      .where('label', '<=', `${lowerCaseInput}~`)
      .get();
  } else {
    result = await firebase
      .firestore()
      .collection('services')
      .where('companyKey', '==', companyKey)
      .where('type', '==', serviceType.toLowerCase())
      .where('label', '>=', lowerCaseInput)
      .where('label', '<=', `${lowerCaseInput}~`)
      .get();
  }

  return result.docs.map((doc) => doc.data());
};

export const updatePeriodicJob = async (key, providerUids) => {
  await firebase
    .firestore()
    .collection('periodicJobs')
    .doc(key)
    .update({
      'job.providerUids': providerUids,
    })
    .catch((error) => console.error(`cannot update periodic jobs ${error}`));
};

export const updateJob = async (key, providerUids) => {
  await firebase
    .firestore()
    .collection('jobs')
    .doc(key)
    .update({ providerUids })
    .catch((error) => console.error(`cannot update periodic jobs ${error}`));
};

export const searchRoutes = async (input) => {
  const lowerCaseInput = input.toLowerCase();

  const result = await firebase
    .firestore()
    .collection('routes')
    .where('companyKey', '==', companyKey)
    .where('label', '>=', lowerCaseInput)
    .where('label', '<=', `${lowerCaseInput}~`)
    .get();

  return result.docs.map((queryEachResult) => queryEachResult.data()) || [];
};

export const getSearchedJobs = async (input, jobType) => {
  const lowerCaseInput = input.toLowerCase();

  let jobCollection = '';
  if (jobType === 'Assigned Jobs') {
    jobCollection = 'jobs';
  } else {
    jobCollection = 'periodicJobs';
  }

  const result = await firebase
    .firestore()
    .collection(jobCollection)
    .where('companyKey', '==', companyKey)
    .where('label', '>=', lowerCaseInput)
    .where('label', '<=', `${lowerCaseInput}~`)
    .get();

  return result.docs.map((queryEachResult) => queryEachResult.data()) || [];
};

export const editUserProfile = (userData) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(userData.uid)
    .set({ ...userData }, { merge: true });
};

export const uploadUserProfileToStorage = async (image) => {
  try {
    const uploadTask = await firebase
      .storage()
      .ref()
      .child(`profile_images/${image.name}`)
      .put(image);

    return uploadTask.ref.getDownloadURL();
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAttendanceDetailOfADay = async (date) => {
  const response = await firebase
    .firestore()
    .collection('attendance')
    .doc(`${date}_${companyKey}`)
    .get();
  return response.data() || {};
};

export const getPresentEmployeesDetails = async (uid, date) => {
  const response = await firebase
    .firestore()
    .collection('attendance')
    .doc(`${date}_${companyKey}`)
    .collection('attendees')
    .doc(uid)
    .get();

  const userName = (
    await firebase.firestore().collection('providers').doc(uid).get()
  ).data().name;

  return { ...response.data(), name: userName };
};

export const getRealTimeLocationOfTheIndoorProviders = (
  jobKey,
  routeKey,
  updateCrews
) => {
  firebase
    .database()
    .ref()
    .child(`indoorLocations/${companyKey}/${jobKey}/${routeKey}`)
    .on('value', (snapshot) => {
      updateCrews(snapshot.val() || {});
    });
};

export const getRouteName = async (routeKey) => {
  const response = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .get();

  return response.data().name;
};

export const getRouteDetailsOfAJob = async (
  jobKey,
  routeKey,
  isPeriodic,
  update
) => {
  let job = '';
  if (isPeriodic) job = 'periodicJobs';
  else job = 'jobs';

  const routeInfo = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .get();

  firebase
    .firestore()
    .collection(job)
    .doc(jobKey)
    .collection('routes')
    .doc(routeKey)
    .onSnapshot((docSnap) => {
      const routeDetail = docSnap.data();
      const route = routeInfo.data();
      const r = { ...route, ...routeDetail };
      update(r);
    });
};

export const getMarkerLogs = async (
  jobKey,
  markerLogKey,
  routeKey,
  working,
  _updateMarkerLogs
) => {
  const query = firebase
    .firestore()
    .collection('marker_logs')
    .doc(markerLogKey);
  if (_updateMarkerLogs) {
    return query.onSnapshot((response) => {
      let logs;
      if (response.data()) {
        logs = [response.data()];
      }

      _updateMarkerLogs(logs ?? []);
    });
  }
  const response = query.get();
  return (await response).docs.map((r) => r.data()) || [];
};

export function getMarkerLogOfAMarker(jobKey, markerKey, _updateMarkerLog) {
  firebase
    .firestore()
    .collection('marker_logs')
    .where('companyKey', '==', companyKey)
    .where('jobKey', '==', jobKey)
    .where('servicePointId', '==', markerKey)
    .onSnapshot((res) => {
      const response = res?.docs.map((d) => d.data()) ?? [];
      _updateMarkerLog(response);
    });
}

export const getAttendanceDetailOfATimePeriod = async (
  startTimeStamp,
  endTimeStamp
) => {
  const response = await firebase
    .firestore()
    .collection('attendance')
    .where('companyKey', '==', companyKey)
    .where('attendanceDate', '>=', startTimeStamp)
    .where('attendanceDate', '<=', endTimeStamp)
    .get();

  return response.docs.map((d) => d.data()) || [];
};

export const getCrewDetailOfATimePeriod = async (
  uid,
  startTimeStamp,
  endTimeStamp
) => {
  const response = await firebase
    .firestore()
    .collectionGroup('attendees')
    .where('attendanceDate', '>=', startTimeStamp)
    .where('attendanceDate', '<=', endTimeStamp)
    .where('uid', '==', uid)
    .get();

  return response.docs.map((d) => d.data()) || [];
};

export const updateCompanyClosingAndOpening = (data) => {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey)
    .set({ ...data });
};

export const getActiveRouteLogs = async (jobKey, routeKey) => {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('routes')
    .doc(routeKey)
    .collection('logs')
    .get();

  return response.docs.map((d) => d.data()) || [];
};

export const getMarkersOfARoute = async (servicePointsKeys = []) => {
  const promise = servicePointsKeys.map((markerKey) => {
    return getServicePointById(markerKey);
  });

  const servicePointInfos = await Promise.all(promise);
  return servicePointInfos;
};

export const getMarkerByBeaconId = async (beaconId) => {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .where('beaconId', '==', beaconId)
    .get();

  return response.docs[0].data() || {};
};

export async function getMarkersForService(type = '') {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .where('companyKey', '==', companyKey)
    .where('serviceType', '==', type)
    .get();

  return response.docs.map((d) => d.data()) ?? [];
}

export async function addOrEditActivity(data) {
  const collection = firebase
    .firestore()
    .collection('customers')
    .doc(companyKey)
    .collection('activities');

  const id = data.id ? data.id : collection.doc().id;

  return collection.doc(id).set({ ...data, id });
}

export function getActivitiesOfACompany(_updateActivities) {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey)
    .collection('activities')
    .onSnapshot((data) => {
      const response =
        data.docs.map((eachActivity) => eachActivity.data()) ?? [];
      _updateActivities(response);
    });
}

export async function deleteActivityDatabase(activityId = '') {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey)
    .collection('activities')
    .doc(activityId)
    .delete();
}

export async function createEditProperty(propertyDetails = {}) {
  const data = { ...propertyDetails };
  const collection = firebase
    .firestore()
    .collection('customers')
    .doc(companyKey)
    .collection('properties');

  const id = data.id ?? collection.doc().id;

  if (data.imageFile) {
    const file = data.imageFile;

    const storage = firebase.storage();

    const storageRef = storage.ref();

    const imageRef = storageRef.child(`property_images/${id}.jpg`);

    const snapshot = await imageRef.put(file);

    const downloadUrl = await snapshot.ref.getDownloadURL();

    data.imageUrl = downloadUrl;
  }

  delete data.imageFile;

  return collection.doc(id).set({ ...data, id });
}

export async function getPropertiesFromFirestore(user, _updateProperties) {
  firebase
    .firestore()
    .collectionGroup('properties')
    .where('customer.uid', '==', user.user_id)
    .onSnapshot((snap) => {
      const res = snap.docs.map((d) => d.data());
      _updateProperties(res);
    });
}

export async function getPropertiesOfCompany() {
  const response = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey)
    .collection('properties')
    .get();

  const properties = response.docs.map((d) => d.data()) ?? [];
  return properties;
}

export function deletePropertyFromFirestore(propertyId) {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey)
    .collection('properties')
    .doc(propertyId)
    .delete();
}

export async function getPropertyById(propertyId) {
  const response = await firebase
    .firestore()
    .collectionGroup('properties')
    .where('id', '==', propertyId)
    .get();

  return response.docs.map((d) => d.data()) ?? [];
}

export async function getMarkersByPropertyKey(propertyKey) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .where('propertyKey', '==', propertyKey)
    .get();

  const markers = response.docs.map((d) => d.data()) ?? [];
  return markers;
}

export async function getJobsForMarkers(markersKeys, startDate, endDate) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .where('allMarkers', 'array-contains-any', markersKeys)
    .orderBy('startedDate', 'desc')
    .where('startedDate', '>=', startDate)
    .get();

  const jobs = response.docs.reduce((acc, curr) => {
    const job = curr.data();
    const jobEndDate = job?.endDate ?? 0;

    if (jobEndDate <= endDate) {
      return [...acc, job];
    }
    return acc;
  }, []);

  return jobs;
}

export async function getMarkersForServiceKey(serviceKey) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .where('serviceKey', '==', serviceKey)
    .get();

  const markers = response.docs.map((d) => d.data()) ?? [];

  return markers;
}

export async function editCategoryInFirestore(
  oldCategoryName,
  newCategoryName
) {
  const response = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey)
    .collection('activities')
    .where('category', '==', oldCategoryName)
    .get();

  const updates = response.docs.map((d) =>
    d.ref.update({ category: newCategoryName })
  );

  return Promise.all(updates);
}

export async function deleteActivitiesOfACategory(categoryName) {
  const response = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey)
    .collection('activities')
    .where('category', '==', categoryName)
    .get();

  const deletions = response.docs.map((d) => d.ref.delete());

  return Promise.all(deletions);
}

export async function completeJob(jobKey) {
  return firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .update({ status: 'completed' });
}

export function sendPasswordResetEmail(email) {
  return firebase.auth().sendPasswordResetEmail(email);
}

export function disableCrewAccount(uid) {
  const disableAccount = firebase
    .functions()
    .httpsCallable('authGroup-authGroup-disableAccount');

  return disableAccount({ uid });
}

export function deleteCrewAccount(uid) {
  const deleteAccount = firebase
    .functions()
    .httpsCallable('authGroup-authGroup-deleteAccount');

  return deleteAccount({ uid });
}

export function enableCrewAccount(uid) {
  const enableAccount = firebase
    .functions()
    .httpsCallable('authGroup-authGroup-enableAccount');

  return enableAccount({ uid });
}

export async function getEventsForMarker(jobKey, markerKey) {
  const response = await firebase
    .firestore()
    .collection('marker_logs')
    .doc(`${jobKey}_${markerKey}`)
    .collection('events')
    .where('markerKey', '==', markerKey)
    .orderBy('timestamp', 'asc')
    .get();

  const markerEvents = response.docs.map((d) => d.data()) ?? [];
  return markerEvents;
}

export async function getPropertyAssignedMarkers(propertiesKeys) {
  if (propertiesKeys?.length === 0) return [];

  const propertyChunks = chunk(propertiesKeys, 10);

  const promise = propertyChunks?.map((keys) => {
    return firebase
      .firestore()
      .collection('servicePoints')
      .where('propertyKey', 'in', keys)
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const markers = getPromiseValue(response);

  return markers;
}

export function getMarkerAssignedToCustomer(user, updateMarkers) {
  firebase
    .firestore()
    .collection('servicePoints')
    .where('customerDetail.uid', '==', user?.user_id)
    .onSnapshot((res) => {
      const response = res.docs.map((d) => d.data());
      updateMarkers(response);
    });
}

export async function getActiveJobsDetails(markers) {
  if (!markers || markers?.length === 0) return [];

  const markersKeys = markers?.map((marker) => marker?.key);

  const markersKeysArray = chunk(markersKeys, 10);

  const promise = markersKeysArray?.map((keys) => {
    return firebase
      .firestore()
      .collection('jobs')
      .where('allMarkers', 'array-contains-any', keys)
      .where('status', '==', 'started')
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const jobs = getPromiseValue(response);

  return jobs;
}

export async function getMarkerLogsForMarkers(markersKeys, date) {
  let startDate;
  let endDate;

  if (date?.startDate) {
    startDate = getTimeInEpoch(date?.startDate);
  }

  if (date?.endDate) {
    endDate = getTimeInEpoch(date?.endDate);
  }

  const markersKeysChunk = chunk(markersKeys, 10);

  const promise = markersKeysChunk?.map((keys) => {
    let ref = firebase
      .firestore()
      .collection('marker_logs')
      .where('servicePointId', 'in', keys)
      .orderBy('startDate', 'desc');

    if (endDate) {
      ref = ref.where('startDate', '<', endDate);
    }

    if (startDate) {
      ref = ref.where('startDate', '>=', startDate);
    }

    return ref.limit(100).get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const markerLogs = getPromiseValue(response);

  return markerLogs;
}

export async function getActiveRoutes(markers) {
  if (!markers || markers?.length === 0) return [];

  const markersKeys = markers?.map((marker) => marker.key);

  const markersChunks = chunk(markersKeys, 10);

  const promise = markersChunks?.map((keys) => {
    return firebase
      .firestore()
      .collectionGroup('routes')
      .where('servicePointsKeys', 'array-contains-any', keys)
      .where('status', '==', 'in_progress')
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const routes = getPromiseValue(response);

  return routes;
}

export async function getActiveRouteDetails(jobKey, activeRouteKey) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('routes')
    .doc(activeRouteKey)
    .get();

  const routeDetails = response.data() ?? {};

  return routeDetails;
}

export async function getMarkersDetails(markersKeys) {
  if (!markersKeys || markersKeys?.length === 0) return [];

  const markersChunks = chunk(markersKeys, 10);

  const promise = markersChunks?.map((keys) => {
    return firebase
      .firestore()
      .collection('servicePoints')
      .where('key', 'in', keys)
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const markers = getPromiseValue(response);

  return markers;
}

export async function getMarkersLogsForAJob(jobKey, markersKeys, routeKey) {
  if (!jobKey || !routeKey || !markersKeys || markersKeys?.length === 0) {
    return [];
  }

  const markersChunks = chunk(markersKeys, 10);

  const promise = markersChunks?.map((keys) => {
    return firebase
      .firestore()
      .collection('marker_logs')
      .where('jobKey', '==', jobKey)
      .where('routeKey', '==', routeKey)
      .where('servicePointId', 'in', keys)
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const markerLogs = getPromiseValue(response);

  return markerLogs;
}

export async function getServiceRequestsOfCustomer(customerUid) {
  const response = await firebase
    .firestore()
    .collectionGroup('workOrders')
    .where('customerUid', '==', customerUid)
    .orderBy('requestedDate', 'desc')
    .get();

  const serviceRequests = response.docs.map((d) => {
    return { urgency: 'HIGH', ...d.data() };
  });

  return serviceRequests;
}

export async function addRequest(requestData) {
  const data = { ...requestData };

  data.requestedDate = data.requestedDate ?? getTimeInEpoch();
  data.status = data.status ?? 'REQUESTED';

  const ref = firebase
    .firestore()
    .collection('servicePoints')
    .doc(data.markerKey)
    .collection('workOrders');

  data.id = data.id ?? ref.doc().id;
  await ref.doc(data.id).set(data);
  return data;
}

export async function getOrderProgress(markerKey, orderKey) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .doc(markerKey)
    .collection('workOrders')
    .doc(orderKey)
    .collection('progress')
    .orderBy('date', 'desc')
    .get();

  const progress = response.docs.map((d) => d.data());

  return progress;
}

export async function getOrderDetail(markerKey, orderKey) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .doc(markerKey)
    .collection('workOrders')
    .doc(orderKey)
    .get();

  let order = {};
  if (response.data()) {
    order = { urgency: 'HIGH', ...(response.data() ?? {}) };
  }

  return order;
}

export async function getJobByWorkOrderId(jobKey) {
  const res = await firebase.firestore().collection('jobs').doc(jobKey).get();

  const job = res.data();
  return job ?? {};
}

export function deleteServiceRequest(request) {
  const { markerKey, id } = request;

  return firebase
    .firestore()
    .collection('servicePoints')
    .doc(markerKey)
    .collection('workOrders')
    .doc(id)
    .delete();
}
